<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  data() {
    return {

      config: {
        wrap: true,
        altFormat: "M j, Y",
        altInput: true,
      },

      date: new Date().toJSON().slice(0, 10),

      rows: [],
      orderData: null,

      selected_contract: null,
      is_creating_invoice: false,

      add_charges: [],
      final_additional_cost: 0,

      invoice_description: ''
    };
  },
  components: {
    Multiselect,
    flatPickr,
  },
  methods: {
    async getOrder() {
      if (!this.order_number) return
      try {
        let response = await axios.get(
            `/container_order/info_for_invoice/${this.order_number}/`
        );
        this.orderData = response.data;
        this.orderData.freight_charges.additional_cost = this.orderData.freight_charges.additional_cost.map((i) => {
          return {
            service: 'Additional Cost',
            ...i
          };
        })
        this.orderData.freight_charges.agreed_rate = this.orderData.freight_charges.agreed_rate.map((i) => {
          return {
            service: 'Freight Charges',
            ...i
          };
        })
        if (this.orderData.freight_charges.additional_cost != []) {
          this.final_additional_cost = this.orderData.freight_charges.additional_cost.reduce((a, b) => a + b.additional_cost, 0)
        }
      } catch {
        alert("Couldn't not get order data");
      }
    },
    async downloadInvoice() {
      let items = this.agreed_rates
          .map((i) => {
            return {
              service: i.service,
              quantity: i.quantity,
              unit: i.container_type,
              amount: (i.agreed_rate).toFixed(2),
              total: (i.agreed_rate * i.quantity).toFixed(2),
            };
          });

      let add_charges = this.add_costs
          .map((i) => {
            return {
              quantity: i.quantity,
              unit: i.container_type,
              amount: (i.additional_cost).toFixed(2),
              total: (i.additional_cost * i.quantity).toFixed(2),
              service: i.service,
            };
          });

      let data = {
        order_number: this.order_number,
        shipper: this.orderData.shipper,
        consignee: this.orderData.consignee,
        departure_country: this.orderData.departure_country,
        destination_country: this.orderData.destination_country,
        items: items,
        date: this.date,
        contract_id: this.selected_contract,
        add_charges: add_charges,
        description: this.invoice_description.trim()
      };

      try {
        this.is_creating_invoice = true;
        let response = await axios.post("/invoice/create/", data);
        let invoice = response.data;

        let url = process.env.VUE_APP_ORDER_URL + invoice.file;
        fetch(url)
            .then((resp) => resp.blob())
            .then((blobobject) => {
              const blob = window.URL.createObjectURL(blobobject);
              const anchor = document.createElement("a");
              anchor.style.display = "none";
              anchor.href = blob;
              anchor.download = url.toString().split("/")[
              url.toString().split("/").length - 1
                  ];
              document.body.appendChild(anchor);
              anchor.click();
              window.URL.revokeObjectURL(blob);
            })
            .catch(() => alert("An error in downloading the file sorry"));
        await Swal.fire({
          icon: "success",
          title: "Invoice downloaded successfully",
          text: "You will be redirected to the order details page",
        });
        await this.$router.push({
          name: "orders_container_detail",
          params: {id: this.order_number},
          query: {invoice: invoice.invoice},
        });
        this.is_creating_invoice = false;
      } catch {
        this.is_creating_invoice = false;
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    },
    splitAddCost(row, index) {
      let add_cost = this.orderData.freight_charges.additional_cost[index]
      add_cost.additional_cost = add_cost.additional_cost / 2
      this.orderData.freight_charges.additional_cost.splice(index + 1, 0, {
        ...add_cost,
      })
    },
    splitAgreedRate(row, index) {
      let add_cost = this.orderData.freight_charges.agreed_rate[index]
      add_cost.agreed_rate = add_cost.agreed_rate / 2
      this.orderData.freight_charges.agreed_rate.splice(index + 1, 0, {
        ...add_cost,
      })
    },
  },
  computed: {
    agreed_rates: {
      get() {
        try {
          return this.orderData.freight_charges.agreed_rate || []
        } catch {
          return []
        }
      },
    },
    add_costs: {
      get() {
        try {
          return this.orderData.freight_charges.additional_cost || []
        } catch {
          return []
        }
      },
    },

    total() {
      try {
        let total = 0;
        this.orderData.freight_charges.agreed_rate.forEach((i) => {
          total += i.agreed_rate * i.quantity;
        });
        this.orderData.freight_charges.additional_cost.forEach((i) => {
          total += i.additional_cost * i.quantity;
        });
        return total;
      } catch {
        return 0
      }
    },

    order_number() {
      return this.$route.params.order_number
          ? this.$route.params.order_number
          : null;
    },
    order_client() {
      return this.orderData.company ? this.orderData.company : '';
    },
    order_shipper() {
      return this.orderData.shipper ? this.orderData.shipper : '';
    },
    order_consignee() {
      return this.orderData.consignee ? this.orderData.consignee : "";
    },
    order_departure_country() {
      return this.orderData.departure_country
          ? this.orderData.departure_country
          : "";
    },
    order_destination_country() {
      return this.orderData.destination_country
          ? this.orderData.destination_country
          : "";
    },
    order_containers() {
      return this.orderData.containers;
    },
    order_contracts() {
      return this.orderData && this.orderData.contracts
          ? this.orderData.contracts.map((i) => {
            return {value: i.id, label: i.name};
          })
          : [];
    },
    form_is_valid() {
      try {
        let agreed_rate_is_ok = this.agreed_rates.filter((i) => {
          return i.quantity <= 0 || i.agreed_rate < 0 || i.container_type === "";
        }).length === 0 && this.agreed_rates.length > 0
        let additional_cost_is_ok = this.add_costs.filter((i) => {
          return i.quantity <= 0 || i.container_type === "" || i.additional_cost < 0
        }).length === 0
        let final_cost_matches_current_updates = this.final_additional_cost === this.add_costs.map(a => a.additional_cost).reduce((a, b) => a + b, 0)
        return agreed_rate_is_ok && additional_cost_is_ok && this.selected_contract && final_cost_matches_current_updates
      } catch {
        return false
      }
    },
  },
  async mounted() {
    await this.getOrder();
  },
};
</script>

<template>
  <div v-if="order_contracts.length > 0" class="row justify-content-center align-items-center">
    <div class="col-xl-9 col-xxl-7" v-if="orderData">
      <div class="card">
        <div class="card-body border-bottom border-bottom-dashed p-4 pb-2">
          <div class="row justify-content-between">
            <div class="col-lg-4">
              <h4 class="text-dark fw-semibold">InterRail Services AG</h4>
              <h6 class="fs-14">Postfach230</h6>
              <h6 class="fs-14">Winkelriedstrasse19</h6>
              <h6 class="fs-14">CH-9001St.Gallen/Switzerland</h6>
            </div>
            <div class="col-lg-5 text-end">
              <img
                  alt="Responsive image"
                  class="img-fluid"
                  src="@/assets/images/interrail-logo.png"
              />
            </div>
          </div>
        </div>

        <div class="card-body p-4">
          <div class="row align-items-end justify-content-between">
            <div class="col-lg-5 col-sm-6">
              <h5 class="text-dark fw-semibold">
                {{ order_client.name }}
              </h5>
              <p class="mb-0 fs-14">
                {{ order_client.address }}
              </p>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div>
                <label for="date-field fs-14">St.Gallen,Date:</label>
                <flat-pickr
                    v-model="date"
                    id="invoicedate"
                    placeholder="Select date and time"
                    :config="config"
                    class="form-control bg-light border-light border-0"
                ></flat-pickr>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body p-4 py-3 border-top border-top-dashed">
          <div class="row align-items-end justify-content-between">
            <div class="col-lg-6">
              <div class="table-responsive">
                <table
                    class="table table-borderless table-sm table-nowrap align-middle mb-0"
                >
                  <tbody>
                  <tr>
                    <th scope="row">SHIPPER:</th>
                    <td>
                      <input
                          :value="order_shipper"
                          type="text"
                          class="form-control bg-light border-0"
                          id="cart-subtotal"
                          placeholder="SHIPPER"
                          readonly
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">CONSIGNEE:</th>
                    <td>
                      <input
                          :value="order_consignee"
                          type="text"
                          class="form-control bg-light border-0"
                          id="cart-subtotal"
                          placeholder="CONSIGNEE"
                          readonly
                      />
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="row align-items-end justify-content-between">
            <div class="col-lg-6">
              <div class="table-responsive">
                <table
                    class="table table-borderless table-sm table-nowrap align-middle mb-0"
                >
                  <tbody>
                  <tr>
                    <th scope="row" class="pe-4">
                      <span class="pe-3">FROM:</span>
                    </th>
                    <td>
                      <input
                          :value="order_departure_country"
                          type="text"
                          class="form-control bg-light border-0"
                          placeholder="Departure Country"
                          readonly
                      />
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="table-responsive">
                <table
                    class="table table-borderless table-sm table-nowrap align-middle mb-0"
                >
                  <tbody>
                  <tr>
                    <th scope="row" class="pe-5">
                      <span class="pe-3">TO:</span>
                    </th>
                    <td>
                      <input
                          :value="order_destination_country"
                          type="text"
                          class="form-control bg-light border-0"
                          placeholder="Destination Country"
                          readonly
                      />
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="border-top border-top-dashed px-4 py-2">
          <b-row gutter-y="4">
            <b-col sm="12">
              <label class="form-label">Description</label>
              <input v-model="invoice_description" class="form-control" placeholder="Description"
                     style="max-width: 450px">
            </b-col>
            <b-col md="8">
              <label class="form-label">Order containers</label>
              <p
                  class="w-75 lh-lg mb-0"
                  v-if="order_containers.filter((i) => i !== null).length > 0"
              >
                {{ order_containers.filter((i) => i !== null).join(", ") }}
              </p>
              <p class="w-75 lh-lg mb-0 text-danger" v-else>
                Order has {{ order_containers.length }} containers. However, the
                container names for your order have not been provided yet!
              </p>
            </b-col>
            <b-col md="4">
              <label class="form-label">Select contract</label>
              <Multiselect
                  :searchable="true"
                  placeholder="Select contract"
                  :options="order_contracts"
                  v-model="selected_contract"
              />
            </b-col>
          </b-row>
        </div>

        <div
            class="card-body p-4 border-top border-bottom border-top-dashed border-bottom-dashed"
        >
          <div class="table-responsive mb-5">
            <table class="table table-borderless table-nowrap mb-0">
              <thead class="align-middle text-center">
              <tr class="table-active">
                <th scope="col">Services</th>
                <th scope="col">Quantity</th>
                <th scope="col">Unit</th>
                <th scope="col">Amount</th>
                <th scope="col">USD</th>
                <th scope="col"></th>
              </tr>
              </thead>
              <tbody>

              <template v-if="agreed_rates && agreed_rates.length > 0">
                <tr>
                  <td colspan="5" class="border-0 text-center pb-0 fw-semibold">AGREED RATES</td>
                </tr>
                <tr
                    class="text-center align-middle"
                    v-for="(row, n) in agreed_rates"
                    :key="n"
                    :class="{ 'deleted-row bg-soft-danger': row.deleted }"
                >
                  <td class="text-start">
                    <input
                        class="form-control border-0 ps-0 fs-13"
                        v-model="row.service"
                        placeholder="Services name"
                    />
                  </td>
                  <td>
                    <span>{{ row.quantity }}</span>
                  </td>
                  <td class="text-end">
                    <input
                        :value="row.container_type"
                        type="text"
                        class="form-control bg-light border-0"
                        placeholder="Container Type"
                        :readonly="!row.is_add_charge === true"
                    />
                  </td>
                  <td class="text-end">
                    <input
                        v-model="row.agreed_rate"
                        type="number"
                        class="form-control bg-light border-0"
                        placeholder="Agreed rate"
                    />
                  </td>
                  <td class="text-end">
                    <div>
                      <VTooltip>
                        <input
                            :value="
                            '$' +
                            (row.agreed_rate * row.quantity).toLocaleString(
                              'en-US'
                            )
                          "
                            type="text"
                            class="form-control bg-light border-0"
                            placeholder="$0.00"
                            readonly
                        />
                        <template #popper>
                          <div>
                            ${{
                              (row.agreed_rate * row.quantity).toLocaleString(
                                  'en-US'
                              )
                            }} = {{ row.agreed_rate }} * {{ row.quantity }}
                          </div>
                        </template>
                      </VTooltip>
                    </div>
                  </td>
                  <td>
                    <button
                        type="button"
                        class="btn btn-icon btn-ghost-secondary bg-soft-success"
                        aria-haspopup="true"
                        aria-expanded="false"
                        @click="splitAgreedRate(row, n)"
                    >
                      <i class="mdi mdi-arrow-split-horizontal text-success fs-5"></i>
                    </button>
                  </td>
                </tr>
              </template>

              <template v-if="add_costs && add_costs.length > 0">
                <tr>
                  <td colspan="5" class="border-0 border-top border-dashed text-center pb-1 fw-semibold">
                    ADDITIONAL COSTS
                  </td>
                </tr>
                <tr
                    class="text-center align-middle"
                    v-for="(row, n) in add_costs"
                    :key="n"
                    :class="{ 'deleted-row bg-soft-danger': row.deleted }"
                >
                  <td class="text-start">
                    <input
                        class="form-control border-0 ps-0 fs-13"
                        v-model="row.service"
                        placeholder="Services name"
                    />
                  </td>
                  <td>
                    <input
                        v-if="row.is_add_charge"
                        class="form-control border-0 ps-0 fs-13 text-center"
                        v-model="row.quantity"
                        placeholder="Quantity"
                        type="number"
                    />
                    <span v-else>{{ row.quantity }}</span>
                  </td>
                  <td class="text-end">
                    <select
                        v-if="row.is_add_charge"
                        v-model="row.container_type"
                        class="form-select"
                    >
                      <option value="20">20</option>
                      <option value="20HC">20HC</option>
                      <option value="40">40</option>
                      <option value="40HC">40HC</option>
                      <option value="45">45</option>
                    </select>
                    <input
                        v-else
                        :value="row.container_type"
                        type="text"
                        class="form-control bg-light border-0"
                        placeholder="Container Type"
                        :readonly="!row.is_add_charge === true"
                    />
                  </td>
                  <td class="text-end">
                    <input
                        v-model="row.additional_cost"
                        type="number"
                        class="form-control bg-light border-0"
                        placeholder="Agreed rate"
                    />
                  </td>
                  <td class="text-end">
                    <div>
                      <VTooltip>
                        <input
                            :value="
                            '$' +
                            (row.additional_cost * row.quantity)
                            .toLocaleString('en-US')
                            "
                            type="text"
                            class="form-control bg-light border-0"
                            placeholder="$0.00"
                            readonly
                        />
                        <template #popper>
                          <div>
                            ${{
                              (row.additional_cost * row.quantity).toLocaleString(
                                  'en-US'
                              )
                            }} = {{ row.additional_cost }} * {{ row.quantity }}
                          </div>
                        </template>
                      </VTooltip>
                    </div>
                  </td>
                  <td>
                    <button
                        type="button"
                        class="btn btn-icon btn-ghost-secondary bg-soft-success"
                        aria-haspopup="true"
                        aria-expanded="false"
                        @click="splitAddCost(row, n)"
                    >
                      <i class="mdi mdi-arrow-split-horizontal text-success fs-5"></i>
                    </button>
                  </td>
                </tr>
              </template>

              <tr class="border-top border-top-dashed">
                <th colspan="3"></th>
                <td class="fw-semibold text-center">
                  <span class="d-block">
                    {{
                      (final_additional_cost - add_costs.map(a => a.additional_cost).reduce((a, b) => a + b, 0))
                          .toFixed(2)
                    }}
                  </span>
                  <i class="bx fw-semibold fs-4" :class="{
                    'bx-check text-success' : final_additional_cost === add_costs.map(a => a.additional_cost).reduce((a, b) => a + b, 0),
                    'bx-x text-danger' : final_additional_cost !== add_costs.map(a => a.additional_cost).reduce((a, b) => a + b, 0),
                  }"></i>
                </td>
                <td class="fw-semibold text-center">
                  ${{ total.toLocaleString("en-US") }}
                </td>
                <th colspan="1"></th>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="mt-3">
            <button
                v-if="!is_creating_invoice"
                :disabled="!form_is_valid"
                @click="downloadInvoice()"
                class="btn btn-success w-100"
            >
              <i class="ri-download-2-line align-bottom me-1"></i> Download
              Invoice
            </button>
            <button
                v-else-if="is_creating_invoice"
                :disabled="true"
                class="btn btn-success w-100"
            >
              <i class="ri-download-2-line align-bottom me-1"></i>
              Downloading Invoice...
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="row justify-content-center mt-5">
    <div class="col-md-4">
      <!-- Warning Alert -->
      <div class="card card-body text-center mt-5 py-5 pb-4 px-4">
        <div class="avatar-lg mx-auto mb-4">
          <div class="avatar-title bg-soft-danger text-danger fs-17 rounded">
            <i class="ri-spam-line display-4"></i>
          </div>
        </div>
        <h4 class="card-title">Invoice generation is not allowed</h4>
        <p class="card-text text-muted">
          We have found that you have no contract with <br/>the client of this
          order. Please, create a contract first<br/>
          and try again later
        </p>
        <router-link
            v-if="orderData"
            :to="{
            name: 'customer_profile_contracts',
            params: {
              slug: order_client.slug,
            },
          }"
            class="btn btn-success"
        >Go to Contracts
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>
.deleted-row {
  position: relative;
}

.deleted-row::after {
  content: "Deleted";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  padding-top: 20px;
  color: red;
  font-weight: bold;
}
</style>
